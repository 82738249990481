<template>
  <div id="resume" class="resume">
    <Progress v-bind:step="step"/>
    <div class="user-info">
      <div class="header">
        <span class="title">人员信息登记</span>
        <span v-if="form.indexNumber" class="index">报名序号：{{ form.indexNumber }}</span>
      </div>
      <el-form class="form" ref="ruleForm" :model="form" :rules="rules" label-width="125px">
        <el-row :gutter="40">
          <el-col :span="8">
            <el-form-item label="申报岗位：" prop="postId">
              <el-select v-model="form.postId" placeholder="请选择申报岗位" style="width: 100%" filterable
              >
                <el-option
                    v-for="item in activePostPublished"
                    :key="item.id"
                    :label="item.post"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="岗位调剂：" prop="canAdjust">
              <el-radio-group v-model="form.canAdjust" @change="canAdjustChange">
                <el-radio v-for="item in sysYesNo"
                          :key="item.dictValue" :label="item.dictValue">
                  {{ item.dictLabel }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col class="title" :span="6"><i class="el-icon-user-solid"></i>基础信息</el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="16">
            <el-row :gutter="40">
              <el-col :span="12">
                <el-form-item label="姓名：" prop="name">
                  <el-input v-model="form.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="民族：" prop="nation">
                  <el-select v-model="form.nation" placeholder="请选择民族" style="width: 100%" filterable>
                    <el-option
                        v-for="item in sysNation"
                        :key="item.dictValue"
                        :label="item.dictLabel"
                        :value="item.dictValue"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="40">
              <el-col :span="12">
                <el-form-item label="身份证号：" prop="idCard">
                  <el-input v-model="form.idCard"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="婚姻状况：" prop="marriageStatus">
                  <el-select v-model="form.marriageStatus" placeholder="请选择婚姻状态" style="width: 100%">
                    <el-option
                        v-for="item in sysMarriageStatus"
                        :key="item.dictValue"
                        :label="item.dictLabel"
                        :value="item.dictValue"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="40">
              <el-col :span="12">
                <el-form-item label="政治面貌：" prop="politicalOutlook">
                  <el-select v-model="form.politicalOutlook" placeholder="请选择政治面貌" style="width: 100%"
                             filterable>
                    <el-option
                        v-for="item in sysPoliticalOutlook"
                        :key="item.dictValue"
                        :label="item.dictLabel"
                        :value="item.dictValue"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="考生身份：" prop="identity">
                  <el-select v-model="form.identity" placeholder="请选择考生身份" style="width: 100%">
                    <el-option
                        v-for="item in regUserIdentity"
                        :key="item.dictValue"
                        :label="item.dictLabel"
                        :value="item.dictValue"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="40">
              <el-col :span="12">
                <el-form-item label="身高(CM)：" prop="tall">
                  <el-input-number v-model="form.tall" style="width: 100%;" :min="130" :max="240"
                                   placeholder="单位：CM"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="体重(KG)：" prop="weight">
                  <el-input-number v-model="form.weight" style="width: 100%;" :min="30" :max="240"
                                   placeholder="单位：KG"></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="40">
              <el-col :span="12">
                <el-form-item label="联系电话：" prop="phonenumber">
                  <el-input v-model="form.phonenumber"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="紧急联系电话：" prop="emergencyPhoneNumber">
                  <el-input v-model="form.emergencyPhoneNumber"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8" class="upload">
            <el-upload
                class="avatar-uploader"
                action="#"
                accept="image/jpeg"
                :show-file-list="false"
                :http-request="uploadImg"
                :before-upload="beforeAvatarUpload">
              <img v-if="avatar" :src="avatar" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <span class="tip">请上传近期免冠2寸照片，</span><br>
            <span class="tip">照片以白底为宜，尽支持JPG格式，2M以内</span>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="户籍所在地：" prop="birthAddress">
              <el-input v-model="form.birthAddress"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="家庭地址：" prop="homeAddress">
              <el-input v-model="form.homeAddress"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="8">
            <el-form-item label="最高学历：" prop="highestEducation">
              <el-select v-model="form.highestEducation" placeholder="请选择最高学历" style="width: 100%">
                <el-option
                    v-for="item in regEducationLevel"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最高学位：" prop="highestDegree">
              <el-select v-model="form.highestDegree" placeholder="请选择最高学位" style="width: 100%">
                <el-option
                    v-for="item in regDegreeLevel"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="普通话等级：" prop="chineseLevel">
              <el-input v-model="form.chineseLevel"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="8">
            <el-form-item label="教师资格证种类及学科等级：" label-width="200px" prop="teacherLevel">
              <el-input v-model="form.teacherLevel"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="专业技术资格：" prop="professionalLevel">
              <el-input v-model="form.professionalLevel"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="外语语种及水平：" prop="foreignLanguageLevel">
              <el-input v-model="form.foreignLanguageLevel"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col class="title" :span="22"><i class="el-icon-s-flag"></i>教育信息（从高中起填）</el-col>
          <el-col :span="2">
            <el-button type="primary" size="medium" @click="addUserEducations">新增</el-button>
          </el-col>
        </el-row>
        <div v-for="(edu,index) in form.userEducations" class="education" :key="'edu_'+index">
          <el-row :gutter="40">
            <el-col :span="8">
              <el-form-item label="起止时间：">
                <el-date-picker
                    v-model="edu.dateTemp"
                    type="monthrange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始月份"
                    end-placeholder="结束月份">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="毕业院校：">
                <el-input v-model="edu.schoolName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="毕业专业：">
                <el-cascader
                    v-model="edu.major"
                    :options="majorOptions" :show-all-levels="false" filterable clearable></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="8">
              <el-form-item label="学历：">
                <el-select v-model="edu.educationLevel" placeholder="请选择学历" style="width: 100%">
                  <el-option
                      v-for="item in regEducationLevel"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学位：">
                <el-select v-model="edu.degreeLevel" placeholder="请选择学位" style="width: 100%">
                  <el-option
                      v-for="item in regDegreeLevel"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="index!==0" :span="2" :push="6">
              <el-button circle type="info" icon="el-icon-delete" @click="removeEducation(index)"></el-button>
            </el-col>
          </el-row>
        </div>
        <el-row :gutter="40">
          <el-col class="title" :span="22"><i class="el-icon-s-finance"></i>工作经历</el-col>
          <el-col :span="2">
            <el-button type="primary" size="medium" @click="addUserWorks">新增</el-button>
          </el-col>
        </el-row>
        <div v-for="(work,index) in form.userWorks" class="work" :key="'work_'+index">
          <el-row :gutter="40">
            <el-col :span="8">
              <el-form-item label="起止时间：">
                <el-date-picker
                    v-model="work.dateTemp"
                    type="monthrange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始月份"
                    end-placeholder="结束月份">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="工作单位：">
                <el-input v-model="work.company"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="职务：">
                <el-input v-model="work.post"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="16">
              <el-form-item label="简介：">
                <el-input type="textarea" v-model="work.remark" rows="5"></el-input>
              </el-form-item>
            </el-col>
            <el-col v-if="index!==0" :span="2" :push="6">
              <el-button circle type="info" icon="el-icon-delete" @click="removeWork(index)"></el-button>
            </el-col>
          </el-row>
        </div>
        <div class="btn-group">
          <el-button :disabled="btnSaveDisabled" type="primary" @click="saveResume">保存</el-button>
          <el-button :disabled="btnSaveDisabled" type="danger" @click="submitResume">提交</el-button>
          <el-button type="success" @click="resetResume">重置</el-button>
          <el-button @click="back">返回</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {listPostPublished} from '@/api/Home'
// import {checkIdcard, getDict, getMajors, saveUserResume, uploadAvatar} from "@/api/Resume";
import {checkIdNumberValid, getDict, getMajors, saveUserResume, uploadAvatar} from "@/api/Resume";

export default {
  name: "Resume",
  props: ['pid'],
  data() {
    // 校验手机号码
    let validatePhoneNumber = (rule, value, callback) => {
      //校验手机号码格式
      const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (phoneReg.test(value)) {
        return callback();
      } else {
        return callback(new Error("错误的手机号码"));
      }
    };
    // 校验紧急联系人手机号码
    let validateEmergencyPhoneNumber = (rule, value, callback) => {
      //校验手机号码格式
      const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (phoneReg.test(value)) {
        // 校验紧急联系人的手机号码不能与联系电话相同
        if (this.form.phonenumber === value) {
          return callback(new Error("不能与联系电话相同"));
        } else {
          return callback();
        }
      } else {
        return callback(new Error("错误的手机号码"));
      }
    };

    // 校验身份证号
    let validateIndexNumber = (rule, value, callback) => {
      //校验手机号码格式
      // let msg = checkIdcard(value)
      let msg = checkIdNumberValid(value)
      if (msg === '验证通过!') {
        return callback()
      } else {
        return callback(new Error('请输入正确的身份证号码'));
      }
    };
    return {
      step: 1,
      value1: null,
      avatar: '',
      btnSaveDisabled: false,
      rules: {
        // postId: [
        //   {required: true, message: '请选择申报岗位'}
        // ],
        name: [
          {required: true, message: '请输入姓名'},
          {max: 10, message: '姓名最大长度为10'}
        ],
        nation: [
          {required: true, message: '请选择民族'}
        ],
        idCard: [
          {required: true, message: '请输入身份证号'},
          {validator: validateIndexNumber}
        ],
        marriageStatus: [
          {required: true, message: '请选择婚姻状态'}
        ],
        politicalOutlook: [
          {required: true, message: '请选择政治面貌'}
        ],
        identity: [
          {required: true, message: '请选择考生身份'}
        ],
        tall: [
          {required: true, message: '请输入身高'},
        ],
        weight: [
          {required: true, message: '请输入体重'},
        ],
        phonenumber: [{required: true, message: '请输入联系电话'},
          {validator: validatePhoneNumber}
        ],
        emergencyPhoneNumber: [
          {required: true, message: '请输入联系电话'},
          {validator: validateEmergencyPhoneNumber}
        ],
        birthAddress: [
          {required: true, message: '请输入户籍所在地'},
          {max: 50, message: '户籍所在地最多50个字'}
        ],
        homeAddress: [
          {required: true, message: '请输入家庭地址'},
          {max: 50, message: '家庭地址最多50个字'}
        ],
        highestEducation: [
          {required: true, message: '请选择最高学历'}
        ],
        highestDegree: [
          {required: true, message: '请选择最高学位'}
        ],
        chineseLevel: [
          {max: 20, message: '普通话等级最多20个字'}
        ],
        teacherLevel: [
          {max: 20, message: '教师资格证种类及学科等级最多20个字'}
        ],
        professionalLevel: [
          {max: 20, message: '专业技术资格最多20个字'}
        ]
      },
      form: {id: null, userId: null, indexNumber: '', phonenumber: '', userEducations: [], userWorks: []},
      activePostPublished: [],
      sysYesNo: [],
      sysNation: [],
      sysMarriageStatus: [],
      sysPoliticalOutlook: [],
      regUserIdentity: [],
      regDegreeLevel: [],
      regEducationLevel: [],
      majorOptions: []
    }
  },
  created() {
    this.form.canAdjust = 'Y'

    this.addUserEducations()
    this.addUserWorks()

    this.getInitData()
  },
  computed: {
    ...mapGetters(["getUser"])
  },
  watch: {
    // 获取vuex的登录状态
    getUser: function (user) {
      if (user.avatar) {
        this.avatar = process.env.VUE_APP_BASE_API + user.avatar
        this.form.avatar = user.avatar
      } else {
        this.form.avatar = this.avatar = ''
      }
    }
  },
  methods: {
    ...mapActions(["setUser"]),
    getInitData() {

      //设置用户头像
      let user = this.$store.getters.getUser
      this.avatar = user.avatar ? process.env.VUE_APP_BASE_API + user.avatar : '';
      this.form.avatar = user.avatar

      //设置用户默认的联系电话
      this.form.phonenumber = user.phonenumber

      //从sessionStorage中获取用户保存的简历
      let resume = sessionStorage.getItem("resume")
      if (resume) {
        this.form = JSON.parse(resume)
        if (this.form.status === '2') {
          this.btnSaveDisabled = false
        }

        //遍历教育经历
        if (this.form.userEducations != null && this.form.userEducations.length > 0) {
          for (let i = 0; i < this.form.userEducations.length; i++) {
            this.form.userEducations[i].beginDate = this.form.userEducations[i].beginDate + '-01'
            this.form.userEducations[i].endDate = this.form.userEducations[i].endDate + '-01'


            this.$set(this.form.userEducations[i], 'dateTemp', [this.form.userEducations[i].beginDate, this.form.userEducations[i].endDate])

            this.form.userEducations[i].major = new Array(2)
            this.form.userEducations[i].major[0] = this.form.userEducations[i].majorTypeId
            this.form.userEducations[i].major[1] = this.form.userEducations[i].majorId
            this.$forceUpdate()
          }
        }else{
          this.form.userEducations=[]
        }

        //遍历工作经历
        if (this.form.userWorks != null && this.form.userWorks.length > 0) {
          for (let i = 0; i < this.form.userWorks.length; i++) {
            // if (this.form.userWorks[i].dateTemp) {
            this.form.userWorks[i].beginDate = this.form.userWorks[i].beginDate + '-01'
            this.form.userWorks[i].endDate = this.form.userWorks[i].endDate + '-01'

            this.$set(this.form.userWorks[i], 'dateTemp', [this.form.userWorks[i].beginDate, this.form.userWorks[i].endDate])
            this.$forceUpdate()
            // }
          }
        }else{
          this.form.userWorks = []
        }

      }

      //获取所有有效职位列表
      listPostPublished({}).then(response => {
        if (response.status === 200) {
          this.activePostPublished = response.data.rows

          if (this.pid) {
            this.disableSelectPost = false
            for (let i = 0; i < this.activePostPublished.length; i++) {
              let item = this.activePostPublished[i]
              if (this.pid === item.id) {
                this.form.postId = this.pid
                break
              }
            }
          }
        }
      })

      //获取是否字典
      getDict('sys_yes_no').then(response => {
        if (response.status === 200) {
          this.sysYesNo = response.data.data
        }
      })

      //获取民族字典
      getDict('sys_nation').then(response => {
        if (response.status === 200) {
          this.sysNation = response.data.data
        }
      })

      //获取婚姻状态字典
      getDict('sys_marriage_status').then(response => {
        if (response.status === 200) {
          this.sysMarriageStatus = response.data.data
        }
      })

      //获取政治面貌字典
      getDict('sys_political_outlook').then(response => {
        if (response.status === 200) {
          this.sysPoliticalOutlook = response.data.data
        }
      })

      //获取政治面貌字典
      getDict('reg_user_identity').then(response => {
        if (response.status === 200) {
          this.regUserIdentity = response.data.data
        }
      })

      //获取学历字典
      getDict('reg_education_level').then(response => {
        if (response.status === 200) {
          this.regEducationLevel = response.data.data
        }
      })

      //获取学位字典
      getDict('reg_degree_level').then(response => {
        if (response.status === 200) {
          this.regDegreeLevel = response.data.data
        }
      })

      //首先获取所有的专业分类，遍历专业分类获取所有专业
      //获取学位字典
      getDict('reg_major_type').then(response => {
        if (response.status === 200) {
          let majorTypeList = response.data.data
          for (let i = 0; i < majorTypeList.length; i++) {
            let majorType = majorTypeList[i]
            getMajors(majorType.dictValue).then(res => {
              if (res.status === 200 && res.data.data.length > 0) {
                let obj = {value: majorType.dictValue, label: majorType.dictLabel, children: []}
                for (let j = 0; j < res.data.data.length; j++) {
                  let major = res.data.data[j]
                  let child = {value: major.id, label: major.majorName}
                  obj.children.push(child)
                }
                this.majorOptions.push(obj)
              }
            })
          }
        }
      })

    },
    canAdjustChange() {
      this.$forceUpdate()
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    uploadImg(params) {
      let file = params.file
      // 根据后台需求数据格式
      const formData = new FormData()
      // 文件对象
      formData.append("avatarfile", file)

      uploadAvatar(formData).then(response => {
        if (response.status === 200) {
          this.form.avatar = response.data.imgUrl

          let user = JSON.parse(sessionStorage.getItem("user"))
          user.avatar = response.data.imgUrl
          sessionStorage.setItem("user", JSON.stringify(user));
          this.setUser(user)
        }
      });
    },
    addUserEducations() {
      if (this.form.userEducations.length < 4) {
        let lastEdu = this.form.userEducations[this.form.userEducations.length - 1]
        if (lastEdu === undefined || (lastEdu.dateTemp && lastEdu.dateTemp.length === 2 && lastEdu.schoolName)) {
          this.form.userEducations.push({})
        } else {
          this.$message({
            message: '您有未完善的教育经历',
            type: 'warning'
          });
        }
      } else {
        this.$message({
          message: '最多只能添加4段教育经历',
          type: 'warning'
        });
      }
    },
    removeEducation(index) {
      this.form.userEducations.splice(index, 1);
    },
    addUserWorks() {
      if (this.form.userWorks.length < 4) {
        let lastUserWork = this.form.userWorks[this.form.userWorks.length - 1]
        if (lastUserWork === undefined || (lastUserWork.dateTemp && lastUserWork.dateTemp.length === 2 && lastUserWork.company)) {
          this.form.userWorks.push({})
        } else {
          this.$message({
            message: '您有未完善的工作经历',
            type: 'warning'
          });
        }
      } else {
        this.$message({
          message: '最多只能添加4段工作经历',
          type: 'warning'
        });
      }
    },
    removeWork(index) {
      this.form.userWorks.splice(index, 1);
    },
    resetResume() {
      this.form = {
        id: this.form.id,
        userEducations: [{}],
        userWorks: [{}],
        avatar: '',
        indexNumber: this.form.indexNumber,
        canAdjust: this.form.canAdjust,
        postId: this.form.postId
      }
      this.avatar = ''
      this.$forceUpdate()
    },
    saveResume() {
      this.form.status = '1'
      this.postResume()
    },
    submitResume() {
      this.form.status = '2'
      this.postResume()
    },
    postResume() {
      if (!this.form.avatar) {
        this.$message({
          message: '请上传个人照片',
          type: 'error'
        });
      } else {
        this.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (this.form.userEducations === null || this.form.userEducations.length === 0 || this.form.userEducations[0].dateTemp === undefined) {
              this.$message({
                message: '请填写教育经历',
                type: 'error'
              });
              return
            }
            // 遍历教育经历
            for (let i = 0; i < this.form.userEducations.length; i++) {
              let edu = this.form.userEducations[i]
              if (edu === undefined || edu.dateTemp === undefined || edu.dateTemp === null || edu.dateTemp.length !== 2 || edu.schoolName === null || edu.schoolName === '' || edu.schoolName === undefined) {
                // this.form.userEducations.splice(i, 1)
                edu = null
              } else {
                edu.beginDate = edu.dateTemp[0]
                edu.endDate = edu.dateTemp[1]

                if (edu.major && edu.major.length === 2) {
                  edu.majorId = edu.major[1]
                }
              }
            }
            if (this.form.userWorks !== null && this.form.userWorks.length > 0) {
              // 遍历工作经历
              for (let i = 0; i < this.form.userWorks.length; i++) {
                let work = this.form.userWorks[i]
                if (work === undefined || work.dateTemp === undefined || work.dateTemp === null || work.dateTemp.length !== 2 || work.company === null || work.company === '' || work.company === undefined) {
                  // this.form.userWorks.splice(i, 1)
                  work = null
                } else {
                  work.beginDate = work.dateTemp[0]
                  work.endDate = work.dateTemp[1]
                }
              }
            }

            this.btnSaveDisabled = true
            if (this.form.status === '1') {
              saveUserResume(this.form).then(response => {
                if (response.status === 200 && response.data.code === 200) {
                  this.form.id = response.data.data.id
                  this.form.indexNumber = response.data.data.indexNumber
                  sessionStorage.setItem('resume', JSON.stringify(response.data.data))
                  this.btnSaveDisabled = false
                  this.notifySucceed('简历保存成功')
                }
              }).catch(err => {
                this.btnSaveDisabled = false
                return Promise.reject(err)
              })
            } else if (this.form.status === '2') {
              this.$confirm('本人承诺: 所有的个人信息和报名材料真实有效，对因提供有关信息，证件不实或违反有关规定造成的后果，责任自负', '承诺书', {
                confirmButtonText: '确定',
                cancelButtonText: '返回',
                center: true,
                type: 'warning'
              }).then(() => {
                saveUserResume(this.form).then(response => {
                  if (response.status === 200 && response.data.code === 200) {
                    sessionStorage.setItem('resume', JSON.stringify(response.data.data))
                    this.$alert('<span>姓名：' + this.form.name + '，电话：' + this.form.phonenumber + '</span><br><span>请妥善保管您的个人信息！</span>', '恭喜您，报名成功！', {
                      confirmButtonText: '确定',
                      dangerouslyUseHTMLString: true,
                      type: 'success',
                      center: true,
                      showClose: false
                    }).then(() => {
                      this.$router.push({path: "/"})
                    })
                  }
                }).catch(err => {
                  this.btnSaveDisabled = false
                  return Promise.reject(err)
                })
              }).catch(() => {
                this.btnSaveDisabled = false
                this.$message({
                  type: 'info',
                  message: '已取消提交'
                });
              });
            } else {
              this.btnSaveDisabled = false
              this.notifyError('简历状态有误')
            }
          }
        })
      }
    },
    back() {
      history.back()
    }
  }
}
</script>

<style>
#resume {
  margin: 0 auto;
  max-width: 1440px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  height: 50px;
  border-bottom: 1px solid #E0E0E0;
}

.header > .title {
  font-size: 24px;
  font-weight: 600;
  color: #1677FF;
  line-height: 33px;
  border-left: 4px solid #1677FF;
  padding-left: 8px;
}

.header > .index {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

#resume > .user-info {
  max-width: 1380px;
  background-color: #ffffff;
  margin-top: 32px;
  padding: 0 30px 0 30px;
}

#resume .form {
  margin-top: 32px;
}

#resume .form .title {
  font-size: 18px;
  font-weight: 600;
  color: #1677FF;
  margin-bottom: 32px;
}

#resume .upload {
  text-align: center;
}

#resume .upload .tip {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

#resume .avatar-uploader > .el-upload {
  border: 1px dashed #DDDDDD;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #F5F5F5;
}

#resume .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

#resume .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 175px;
  height: 244px;
  line-height: 244px;
  text-align: center;
}

#resume .avatar {
  width: 175px;
  height: 244px;
  display: block;
}

#resume .btn-group {
  margin-top: 100px;
  padding-bottom: 50px;
  text-align: center;
}

#resume .btn-group button {
  margin: 8px;
}
</style>
