import request from '@/utils/request'

// 获取字典表内容
export function getDict(dictType) {
    return request({
        url: '/system/dict/data/type/' + dictType,
        method: 'get'
    })
}

// 用户头像上传
export function uploadAvatar(data) {
    return request({
        url: '/system/user/profile/avatar',
        method: 'post',
        data: data
    })
}

// 根据专业分类获取专业信息
export function getMajors(type) {
    return request({
        url: '/client/listMajorByType/' + type,
        method: 'get'
    })
}

// 保存用户简历
export function saveUserResume(data) {
    return request({
        url: '/client/saveUserResume',
        method: 'post',
        data: data
    })
}

// 身份证号校验
export function checkIdcard(idcard) {
    let Errors = ["验证通过!", "身份证号码位数不对!", "身份证号码出生日期超出范围或含有非法字符!",
        "身份证号码校验错误!", "身份证地区非法!"];
    let area = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外"
    };
    let Y, JYM;
    let S, M;
    let idcard_array;
    let ereg;
    idcard_array = idcard.split("");
    // 地区检验
    if (area[parseInt(idcard.substr(0, 2))] == null)
        return Errors[4];
    // 身份号码位数及格式检验
    switch (idcard.length) {
        case 15:
            if ((parseInt(idcard.substr(6, 2)) + 1900) % 4 === 0
                || ((parseInt(idcard.substr(6, 2)) + 1900) % 100 === 0 && (parseInt(idcard
                    .substr(6, 2)) + 1900) % 4 === 0)) {
                ereg = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}$/; // 测试出生日期的合法性
            } else {
                ereg = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}$/; // 测试出生日期的合法性
            }
            if (ereg.test(idcard))
                return Errors[0];
            else
                return Errors[2];
        case 18:
            // 18位身份号码检测
            // 出生日期的合法性检查
            // 闰年月日:((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))
            // 平年月日:((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))
            if (parseInt(idcard.substr(6, 4)) % 4 === 0
                || (parseInt(idcard.substr(6, 4)) % 100 === 0 && parseInt(idcard
                    .substr(6, 4)) % 4 === 0)) {
                ereg = /^[1-9][0-9]{5}19[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/; // 闰年出生日期的合法性正则表达式
            } else {
                ereg = /^[1-9][0-9]{5}19[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/; // 平年出生日期的合法性正则表达式
            }
            if (ereg.test(idcard)) {// 测试出生日期的合法性
                // 计算校验位
                S = (parseInt(idcard_array[0]) + parseInt(idcard_array[10])) * 7
                    + (parseInt(idcard_array[1]) + parseInt(idcard_array[11]))
                    * 9
                    + (parseInt(idcard_array[2]) + parseInt(idcard_array[12]))
                    * 10
                    + (parseInt(idcard_array[3]) + parseInt(idcard_array[13]))
                    * 5
                    + (parseInt(idcard_array[4]) + parseInt(idcard_array[14]))
                    * 8
                    + (parseInt(idcard_array[5]) + parseInt(idcard_array[15]))
                    * 4
                    + (parseInt(idcard_array[6]) + parseInt(idcard_array[16]))
                    * 2 + parseInt(idcard_array[7]) * 1
                    + parseInt(idcard_array[8]) * 6 + parseInt(idcard_array[9])
                    * 3;
                Y = S % 11;
                M = "F";
                JYM = "10X98765432";
                M = JYM.substr(Y, 1); // 判断校验位
                if (M === idcard_array[17])
                    return Errors[0]; // 检测ID的校验位
                else
                    return Errors[3];
            } else
                return Errors[2];
        default:
            return Errors[1];
    }
}

/**
 * 校验身份证号合法性
 */
export function checkIdNumberValid(num){
    let tip = '输入的身份证号有误，请检查后重新输入！';
    num = num.toUpperCase();

    let len, re;
    len = num.length;
    if(len === 0) return tip;

    //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
    if (!(/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num))){
        return tip
    }

    //验证前两位地区是否有效
    let aCity= {11:"北京",12:"天津",13:"河北",14:"山西",15:"内蒙古",21:"辽宁",22:"吉林",23:"黑龙江",31:"上海",
        32:"江苏",33:"浙江",34:"安徽",35:"福建",36:"江西",37:"山东",41:"河南",42:"湖北",43:"湖南",
        44:"广东",45:"广西",46:"海南",50:"重庆",51:"四川",52:"贵州",53:"云南",54:"西藏",61:"陕西",
        62:"甘肃",63:"青海",64:"宁夏",65:"新疆",71:"台湾",81:"香港",82:"澳门",91:"国外"}

    if(aCity[parseInt(num.substr(0,2))] == null){
        return tip;
    }

    let arrSplit
    let dtmBirth
    let bGoodDay

    //当身份证为15位时的验证出生日期。
    if (len === 15){
        re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
        arrSplit = num.match(re);

        //检查生日日期是否正确
        dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
        bGoodDay = (dtmBirth.getYear() === Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) === Number(arrSplit[3])) && (dtmBirth.getDate() === Number(arrSplit[4]));
        if (!bGoodDay){
            return tip;
        }
    }

    //当身份证号为18位时，校验出生日期和校验位。
    if (len === 18){
        re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
        arrSplit = num.match(re);
        //检查生日日期是否正确
        dtmBirth = new Date(arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]);
        bGoodDay = (dtmBirth.getFullYear() === Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) === Number(arrSplit[3])) && (dtmBirth.getDate() === Number(arrSplit[4]));
        if (!bGoodDay){
            return tip;
        }
        else{
            //检验18位身份证的校验码是否正确。
            //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
            let valnum
            let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
            let arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
            let nTemp = 0, i;
            for(i = 0; i < 17; i ++){
                nTemp += num.substr(i, 1) * arrInt[i];
            }
            valnum = arrCh[nTemp % 11];
            if (valnum != num.substr(17, 1)){
                return tip;
            }
        }
    }
    return '验证通过!';
}

